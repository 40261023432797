export const Environment = {
	AppMeta: {
		Environment: 'TEST',
	},
	API: {
		Path: 'https://func-eop-ac-test-westeu-002.azurewebsites.net/api',
		Headers: {
			'Authorization-ZeissId': '',
		},
		Params: {
			code: 'M9PCHvjZ10E-F-9P1PS3Xf96z3ygFchmz_j1lA6eHv3tAzFudusjIg==',
		},
	},
};
